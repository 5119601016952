<template>
    <div style="background:#f2f5fb">
        <div>
          <div class="columns">
            <div>
              <div class="jsmb-container-9">
                <LeftSection />
              </div>
            </div>
            <!-- <div class="column is-4 is-sticky" :class="$mq | mq({
                desktop: 'jsmb-side',
                wizard: 'jsmb-side',
                laptop: 'jsmb-side',
                tablet: 'self-vcentered',
                mobile: 'self-vcentered',
              })"> -->
            <div class="main-container-form is-hidden-mobile">
              <div id="access" class="jsmb-container-form">
                <FormLandingStockeur />
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="container" :class="$mq | mq({
          desktop: 'is-fluid',
        })">
          <div class="columns">
            <div class="column  jsmb-container-left" :class="$mq | mq({
                desktop: 'container is-6',
                wizard: 'is-8',
                laptop: 'is-8',
              })">
              <div class="jsmb-container-9">
                <LeftSection />
              </div>
            </div>
            <div class="column is-4 is-sticky" :class="$mq | mq({
                desktop: 'jsmb-side',
                wizard: 'jsmb-side',
                laptop: 'jsmb-side',
                tablet: 'self-vcentered',
                mobile: 'self-vcentered',
              })">
              <div class="jsmb-container-E">
                <FormLandingStockeur />
              </div>
            </div>
          </div>
        </div> -->

    </div>
</template>


<script>
import LeftSection from '@/components/landing-stockeur/landing-sections/LeftSection.vue'
import FormLandingStockeur from '@/components/landing-stockeur/landing-sections/FormLandingStockeur.vue'
import { setTimeout } from 'timers';

export default {
  name: 'LandingStockeur',
  components: {
    LeftSection,
    FormLandingStockeur,
  },
  data () {
    return {
    }
  },
  updated () {
    // this.resizeViewport()
  },
  mounted () {
    setTimeout(()=> {
      document.querySelector('#launcher').style.display = "none"
    },3000);
  },
  methods: {

    /*setStyle () {
      let myBody = document.querySelector('body').offsetWidth, 
        windowHeight = window.innerHeight,
        windowWidth = window.outerWidth,
        wizardWidth = document.querySelector('.jsmb-wizard').offsetWidth,
        navbarHeight = document.querySelector('.navbar').offsetHeight,
        side = document.querySelector('.jsmb-side'),
        
        containerLeft = document.querySelector('.jsmb-container-left').offsetHeight,
        
        diff = windowHeight - navbarHeight

      if (side !== null) {
        side.style.top = ((diff/2)-(side.offsetHeight/2))+"px"
        if(this.step === 3){
          side.style.top = ((diff/2)-(side.offsetHeight))+"px"
        }
        if(window.innerWidth >= 1400){
          let decal = ((myBody-wizardWidth)/4)-(side.offsetWidth/2);
          side.style.right = decal + "px";
        }
      }

    },

    resizeViewport () {
      let the_timer,
          viewportWidth = window.innerWidth || document.documentElement.clientWidth;



      if(viewportWidth > 768){
        this.setStyle();
        clearTimeout(the_timer);
        the_timer = setTimeout(() => {
          window.addEventListener('resize', ()=> {
            this.setStyle()
          })
        }, 75);
      }
    },*/


  },
  created() {

  },
  metaInfo () {
    let siteName = this.$store.state.globalConstant.SITE_NAME,
      baseUrl = this.$store.state.globalConstant.BASE_URL,
      canonical = this.$router.resolve({ name: 'LandingStockeur' });

    let title = (siteName+' - Déposer mon Annonce - Stockage de bateau chez les particuliers et professionnels - Hivernage Bateau - stockage bateau hors d\'eau'),
      description = (siteName+' est le spécialiste du stockage de bateaux chez les particuliers et professionnels. Déposez votre annonce concernant la recherche d\'un bateau-skipper ou d\'un bateau-stockeur, pour l\'hivernage, stockage de votre bateau.');

    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      titleTemplate: title,
      link: [
        { rel: 'canonical', href: (baseUrl+canonical.href) },
      ],
      meta: [
        { 
          vmid: 'description', 
          name: 'description', 
          content: description 
        },
        {
          'property': 'og:title',
          'content': title,
          'vmid': 'og:title'
        },
        {
          'property': 'og:description',
          'content': description
        },
        {
          'property': 'og:url',
          'content': (baseUrl+this.$route.fullPath),
          'vmid': 'og:url'
        },
        {
          'property': 'og:image',
          'content': (baseUrl+require('./../../assets/og-image.png')),
          'vmid': 'og:image'
        },
        {
          'property': 'og:image:secure_url',
          'content': (baseUrl+require('./../../assets/og-image.png')),
          'vmid': 'og:image:secure_url'
        },
        {
          'property': 'twitter:title',
          'content': title,
          'vmid': 'twitter:title'
        },
        {
          'property': 'twitter:description',
          'content': description,
          'vmid': 'twitter:description'
        },
        {
          'property': 'og:image:alt',
          'content': title,
          'vmid': 'og:image:alt'
        },
        {
          'property': 'twitter:image',
          'content': (baseUrl+require('./../../assets/tw-card.jpg')),
          'vmid': 'twitter:image'
        },
      ]
    }
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  .jsmb-container-9 {
      background: #fff;
      position: relative;
      padding:2vw;
      width:64vw;
  }
  @media screen and (max-width:959px){  
    .jsmb-container-9 { 
      width: 55vw
    }
  }
  .main-container-form {
    width: 100%;
    min-height: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0 1vw;
  }
  .jsmb-container-form {
    position: sticky;
    max-width: 640px;
    top:154px;
  }
   @media screen and (max-height:800px){
    .jsmb-container-form {
      position: relative;
      top: 0;
    }
  } 

  @media screen and (min-width:1400px){
    .jsmb-side,
    .self-vcentered {
      position: fixed;
      max-width: 360px;
      right: 0;
      top:calc(50vh - 112px);
    }
  }

  @media screen and (max-width:1399px){
    .jsmb-side,
    .self-vcentered {
      position: fixed;
      max-width: 360px;
      right: 0 !important;
      margin-left:2rem;
      top:calc(50vh - 112px);
    }
    .jsmb-container-left {
      position: relative;
      max-width: 65vw;
    }
  }

  @media screen and (max-width:769px){
    .jsmb-container-left {
      max-width: inherit;
      left:inherit;
    }
    .jsmb-container-9 {
      width: 100%
    }
    .self-vcentered {
      position:relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      position: relative;
      max-width: inherit;
      top: 0 !important;
      right:25px !important;
    }
  }




  

</style>
<!-- CSS global -->
<style>

</style>
