<template>
  <div class="columns is-multiline landing-simulator has-bottom-margin-3 has-drop-shadow">
    
    <div class="column is-3-desktop is-12-tablet this-flex-centered has-background-danger has-text-left-desktop has-text-centered-tablet has-text-centered-mobile">
      <h3 class="has-text-weight-bold has-text-white estimation">ESTIMEZ<br> VOTRE REVENU</h3>
    </div>
    <div class="column is-6-desktop is-12-tablet">
      <small class="is-uppercase longueur">Superficie de votre emplacement</small>
      <b-tooltip always :label="String(surface + ' m²')" position="is-right">
        <input class="slider"  v-model="surface" step="1" min="15" max="3000" type="range"/>
      </b-tooltip>
    </div>
    <div class="results column is-3-desktop is-12-tablet">
      <span class="is-size-simulator has-text-weight-bold is-danger">{{ this.earnings }}€<span class="month">/mois</span></span>
    </div>
    
  </div>
</template>

<script>
import { SIMULATOR_REQUEST } from '@/store/modules/landing/actions/landing.actions'
import { watchFile, watch } from 'fs';

export default {
  name: 'Simulator',
  data () {
    return {
      earnings: 393,
      surface: 100,
    }
  },
  watch: {
    surface () {
      this.earnings = Math.round( (this.surface * 393) / 100)
    } 
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  .has-left-border{
    border-left: solid 1px #F2F2F2;
  }
  .has-custom-padding{
    padding: 3% 6%;
  }
  .is-danger {
    color:#df5051;
  }
  .has-bottom-padding-1{
    padding-bottom: 1rem;
  }

  .has-margin-bottom {
    margin-bottom: 2rem;
  }
  .has-margin-bottom-1 {
    margin-bottom: 1rem;
  }
  .desc {
    font-size: 13px;
  }
  .unused-space {
    font-size:25px;
  }
  .block {
    padding: 2rem 2.5rem;
    width: 40rem;
    line-height: 1.5rem;
  }
  .block em {
    display: block;
  }
  .is-font-size-cta {
    font-size:1.8rem;
    line-height:1.8rem;
  }
  .simulator-form {
    padding-top: 1.5rem;
  }
  .simulator-form .field {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .simulator-form .field *:not(:first-child) {
    padding-left: 0.5rem;
  }
  .simulator-form input[type="text"] {
    padding: 1rem 0.5rem;
    border: solid 1px rgba(255, 255, 255, 0.3);
    border-radius: 0;
    color: rgba(255, 255, 255, 0.5);
    /* max-width: 5rem; */
  }
  .simulator-form .simulator-form-submit{
    padding-top: 1rem;
  }
  .simulator-form .button {
    padding: 0rem 2rem;
    border-radius: .2rem;
  }
  .month {
    font-size: 1rem
  }
  .simulText {
    font-size:0.9rem;
    width: max-content;
  }
  /* Simul */
  .landing-simulator {
    align-items: stretch;
  }
  .landing-simulator .column {
    padding: 3vh 1.5vw;
  }
  .has-drop-shadow {
    background:#fff;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3)
  }
  .this-flex-centered {
    display:flex;
    align-items: center
  }
  .estimation {
    font-size: 1.4vw;
    line-height: 1.4vw;
  }
  .is-size-simulator {
    font-size: 3vw;
    line-height: 3vw;
    margin: 0 auto;
  }
  @media screen and (max-width:1087px){
    .estimation {
      font-size: 3vw;
      line-height: 3vw;
    }
    .results {
      font-size: 5vw;
      line-height: 5vw;
    }
    .this-flex-centered { 
      justify-content: center;
    }
    .is-size-simulator {
      font-size: 8vw;
      line-height: 3vw;
    }
  }

  
  /*  */
  @media screen and (max-width:768px){
    .results {
      display: inline-flex;
      flex-direction: column;
      align-items: center !important;
    } 
    .simulText {
      margin-bottom: 2rem;
    }
    /* .block {
      height: 24rem;
    } */
  }
  .b-tooltip {
    display: flex;
  }
  .slider {
    -webkit-appearance: none;
    width: 82%;
    height: 10px;
    border-radius: 5px;   
    background: #0ec0b7;
    outline: none;
    opacity: 1;
    -webkit-transition: .2s;
    transition: opacity .2s;
    padding: 0;
  }
  .slider::-webkit-slider-thumb {
    min-height: 20px;
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%; 
    background: #0ec0b7;
    /*border:1px solid #fff;*/
    cursor: pointer;
  }
  .slider::-moz-range-thumb {
    min-height: 20px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #0ec0b7;
    cursor: pointer;
  }

  .slider::-ms-thumb {
    min-height:10px;
    width: 10px;
    height: 10px;
    border-radius: 50%; 
    background-color: #0ec0b7;
    border:2px solid #fff;
    cursor: pointer;
  } 

  .b-tooltip.is-right:after {
    left: calc(82%);
    top: 3px;
    background: transparent;
    box-shadow: none;
    color: black;
    z-index: 12;
  }
  .b-tooltip.is-right.is-primary:before {
    left: calc(82% + 2px);
    top: 3px;
    border-right: 5px solid transparent;

  }
  .is-cta-white {
    background: #fff;
    border: none;
    color:#0ec0b6
  }
  .b-tooltip {
    margin-top:1rem; 
  }
  @media only screen and (max-width:769px){
    .longueur {
      margin-bottom: 1rem;
    } 
  }

  @media only screen and (max-width:569px){
    .is-size-simulator {
      font-size: 3rem;
      margin: 1rem auto;
    }
    .simulText {
      font-size: 0.8rem;
    }
  }
  .results {
    display:flex; 
    align-items:baseline;
    margin:0 auto;
    padding-top: 1rem;
  }
  .results > .columns {
    height:3rem;
    align-items: baseline;
  }
  .results strong {
    color:#fff
  }


</style>
