<template>
  <div>
      <div class="columns has-bottom-margin-3 has-top-margin-3">
        <div class="column is-12">
          <h1 class="has-text-primary has-text-weight-bold is-size-3-desktop is-size-4-tablet is-size-4-mobile">Vous avez un jardin ? Un garage ?<br class="is-hidden-desktop is-hidden-tablet">Un entrepôt ?<br>Gagnez de l’argent avec vos m<sup>2</sup> disponibles</h1>
        </div>
      </div>

      <div class="columns is-mobile is-vcentered">
        <div class="column is-6">
              <figure>
                <img :src="require('./../../../assets/landing-stockeur/illustration-landing-stockeur.png')" alt="" style="display:block">
              </figure>
        </div>
        <div class="column is-1"></div>
        <div class="column is-4">
          <p style="font-size: calc( 0.2vw + 12px )"><em>+ de 50 000 plaisanciers sont à la recherche d’un emplacement pour leur bateau</em></p>
        </div>
      </div>

      <Simulator />

      <div class="columns has-bottom-margin-3">
        <div class="column">
          <h2 class="is-size-2-desktop is-size-3-tablet is-size-3-mobile has-text-weight-bold has-background-primary has-text-white has-title-padding has-letter-spacing this-title has-drop-shadow">JE LOUE MON ESPACE</h2>
        </div>
      </div>

      <div class="columns is-multiline is-variable is-3 has-bottom-margin-3 step-one">
        
        <div class="column is-2-desktop is-offset-2-desktop is-12-tablet">
          <p class="number-title has-text-danger has-text-weight-bold has-bottom-margin-1  has-text-right-desktop has-text-shadow">1.</p>
        </div>

        <div class="column is-8-desktop is-12-tablet">
          <h3 class="has-text-danger is-uppercase has-text-weight-bold is-size-4 has-bottom-margin-1">inscrivez-vous gratuitement<br>et Ajoutez votre Espace de Stockage</h3>
          <p class="has-bottom-margin-1"><span class="has-text-weight-bold">JE CRÉER MON COMPTE</span> en sélectionnant si je suis un particulier ou un professionnel</p>
          <p class="has-text-weight-bold">JE CRÉER MON ANNONCE</p>
          <p class="has-bottom-margin-1">Je renseigne les informations générales liées à mon offre locative:</p>
          <ul class="addImage">
            <li>Où est situé ma location ?</li>
            <li>Qu'est-ce que je souhaite stocker:</li>
            <li>un bateau et une remorque,</li>
            <li>uniquement une remorque,</li>
            <li class="has-bottom-margin-1">Type d'espace</li>
          </ul>
          <p class="has-bottom-margin-1"><span class="has-text-weight-bold">Vous choisissez le mode de stockage:</span> privé avec un seul locataire dans l'espace ou partagé avec plusieurs locataires.</p>
          <p class="has-bottom-margin-1"><span class="has-text-weight-bold">Vous déterminez les conditions d'accès:</span> sur rendez-vous ou avec remise des clés.</p>
          <p>Nous vous conseillons de mettre en avant toutes options relatives à la sécurité, qui rassureront le Skipper. J'intègre des photos et détaille mes conditions (tarifs, coordonnées ...etc).</p>
          <p class="has-text-weight-bold has-bottom-margin-1">PLUS VOTRE PROFIL EST FOURNI PLUS VOUS AUGMENTEZ VOS CHANCES DE TROUVER UN LOCATAIRE RAPIDEMENT !</p>
          <button class="cta-button has-text-white control is-danger button is-hidden-tablet" type="button" @click="isCardModalActive=true">          
            Créer un compte
          </button>
        </div>
      
        
      </div> 

      <div class="columns is-multiline is-variable is-3 has-bottom-margin-3 step-two">
        <div class="column is-2-desktop is-12-tablet">
          <p class="number-title has-text-primary has-text-weight-bold has-bottom-margin-1 has-text-right-desktop has-text-shadow">2.</p>
        </div>

        <div class="column is-7-desktop is-12-tablet">
          <h3 class="has-text-primary is-uppercase has-text-weight-bold has-bottom-margin-1 is-size-4">Accueillez votre bateau-skipper</h3>
          <p><span class="has-text-weight-bold">Vous discutez avec votre locataire</span> avant d'accueillir son bateau via notre plateforme.</p>
          <p>Vous pouvez notamment définir ensemble l'horaire souhaité pour l'entrée des lieux.</p>          
        </div> 
      </div> 

      <div class="columns is-multiline is-variable is-3 has-bottom-margin-3">
        <div class="column is-2-desktop is-offset-2-desktop is-12-tablet">
          <p class="number-title has-text-danger has-text-weight-bold has-bottom-margin-1 has-text-right-desktop has-text-shadow">3.</p>
        </div>

        <div class="column is-8-desktop is-12-tablet">
          <h3 class="has-text-danger is-uppercase has-text-weight-bold is-size-4 has-bottom-margin-1">recevez votre revenu</h3>
          <p class="has-bottom-margin-1"><span class="has-text-weight-bold">Jestockemonbateau simplifie votre gestion.</span> Grâce à notre plateforme sécurisée vous recevez votre paiement en une seule fois pour une courte durée ou chaque mois pendant toute la durée de la location pour une longue durée.</p>
          <ul>
            <li class="has-bottom-margin-1"><span class="has-text-weight-bold">La courte durée, moins de 30 jours:</span> dans ce cas, l'adhésion peur se faire pour une journée, un week-end, une semaine, dix jours, etc., jusqu'à un maximum de 29 jours pleins. Le tarif consenti entre le Bateau-Skipper et le Bateau-Stockeur est réglé dans son intégralité le jour d'arrivé de votre Bateau-Skipper.</li>
            <li class="has-bottom-margin-1"><span class="has-text-weight-bold">La longue durée, au-delà de 30 jours :</span> l'adhésion est conclue pour un mois minimum puis le nombre de mois souhaités jusqu'à une année. La première mensualité est payé à la signature du contrat ; les réglements suivants sont effectués de façon mensuelle à la date anniversairedu contrat.</li>
          </ul>
          <button class="cta-button has-text-white button is-danger control is-hidden-tablet" type="button" @click="isCardModalActive=true">          
            Créer un compte
          </button>
        </div> 
      </div> 

      <div class="columns has-bottom-margin-3 final-step">
        <div class="column is-3-desktop is-offset-8-desktop is-6-tablet is-offset-4-tablet has-text-weight-bold is-size-4">
          <p class="has-text-primary">A vous de jouer !</p>
          <p class="has-text-primary rmv">CRÉEZ VOTRE COMPTE !</p>
          <a class="button is-danger thisShow is-hidden-mobile">Creez votre compte</a>
        </div>

        </div>
        <b-modal :active.sync="isCardModalActive" :width="640" has-modal-card class="modalLandingStockeur">
          <FormLandingStockeur />
        </b-modal>   
        <a @click="isCardModalActive=true" class="button is-danger is-large btnFixed">Déposer mon annonce</a>          
      </div>
  </div>
</template>
<script>
import Simulator from '@/components/landing-stockeur/landing-sections/Simulator.vue'
import FormLandingStockeur from '@/components/landing-stockeur/landing-sections/FormLandingStockeur.vue'


export default {
  name: 'LeftSection',
  components: {
    Simulator,
    FormLandingStockeur
  },
  data () {
    return {
      isCardModalActive:false,
    }
  },
  mounted () {
    let scrolled = document.querySelector('body');
    document.querySelector('.thisShow').addEventListener('click', ()=> {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });

      console.log('test')
    });
  }
}
</script>

<!-- Add "scoped"attribute to limit CSS to this component only -->
<style scoped lang="css">

  .thisShow {
    display: none;
  }
@media screen and (max-height:800px){
  .rmv {
    display: none;
  }
  .thisShow {
    display: block
  }

}
  .btnFixed {
    display:none
  }

  @media screen and (max-width:768px){
    .btnFixed {
      display:block;
      position:fixed;
      bottom:0.7rem;
      right: 0.7rem;
      left: 0.7rem;
    }
  }
  .final-step {
    position: relative;
  }
  .has-drop-shadow {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3)
  }
  .has-text-shadow {
    text-shadow: 0 5px 5px rgba(0, 0, 0, 0.3)
  }
  
  .addImage:after {
    content: url('./../../../assets/landing-stockeur/arrow-red.png');
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) scale(0.75);
    left: 59vw;
    z-index: 12;
  }
  .final-step:after {
    content: url('./../../../assets/landing-stockeur/arrow-green.png');
    position: absolute;
    left: 57vw;
    top: -60%;
    transform: scale(0.75);
    z-index: 12;
  }

  @media screen and (max-width: 1778px) {
    .addImage:after, .final-step:after {
      display: none;
    }
  }

  .is-font-size-cta {
    font-size: 1.8rem;
    line-height: 1.8rem;
  }

  .has-title-padding {
    padding: 1rem 3rem;
  }

  .number-title {
    line-height: 4rem;
    font-size: 6rem;
    /*font-weight: 900;*/
  }

 .has-bottom-margin-1 {
    margin-bottom: 1rem;
  } 

  .has-bottom-margin-3 {
    margin-bottom: 3rem;
  }

 .has-right-margin-neg {
    margin-right: -1rem;
  }

 .has-left-margin-1 {
    margin-left: 1rem;
  }

 .has-left-margin-4 {
    margin-left: 4rem;
  }

  .has-letter-spacing {
    letter-spacing: 2px;
  }

  .has-top-margin-3 {
    margin-top: 3rem;
  }

  .cta-button {
    margin: 0 auto;
    display: block;
  }

  .red-arrow {
    margin-top: 12rem;
  }

  .arrow {
    z-index: 500;
  }

  ul {
    list-style-image: url('./../../../assets/landing-stockeur/list.png');
  }

  li {
    list-style-position: inside;
    text-indent: 1rem;
  }
  .this-title {
    padding-right: 10rem;
    display:inline-block;
    margin-bottom: 2rem;
    margin-top: 3rem;
  }
  @media screen and (max-width:1123px) {
  .this-title {
      padding: 1rem;
      width: 100%;
      display: inline-block;
      margin-bottom: 1rem;
      margin-top: 1.5rem;
    }
  }

	.modalLandingStockeur .button {
		margin-top: 2rem;
	}
	.modalLandingStockeur h3,
	.modalLandingStockeur .jsmb-disponibilite small {
		margin-top: 2rem;
		margin-bottom: 1rem;
	}
  .modalLandingStockeur .animation-content {
      overflow-y: scroll;
  }


.step-one,
.step-two {
  margin-bottom: 5rem;
}
</style>

<style  lang="css">
  .modalLandingStockeur .animation-content {
    z-index: 12 !important
  }
  @media screen and (min-width:320px) and (max-width:1049px) and (orientation: portrait) {
    .modalLandingStockeur {
      overflow-y: scroll;
      padding-top: 16rem;
    }
    .modalLandingStockeur .modal-close {
        z-index: 666;
        background: #df5051;
    }
}
  @media screen and (min-width:320px) and (max-width:1049px) and (orientation: landscape) {
  	.modalLandingStockeur {
      overflow-y: scroll;
      padding-top: 16rem;
    }
    .modalLandingStockeur .modal-close {
        z-index: 666;
        background: #df5051;
    }
}
</style>
