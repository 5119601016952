<template>
    <div class="has-white-bg has-padding-8">
      <h2 class="has-text-primary has-text-centered is-uppercase is-size-4 has-text-weight-bold">Créer un compte</h2>    
      <p class="has-margin-top-1 has-margin-bottom-1 has-text-centered is-size-6 has-bottom-margin-1">Je ne suis pas incrit au service</p> 
        <form @submit.prevent="signup">
          <div>
            <h3 class="has-text-primary has-text-centered has-text-weight-bold is-uppercase has-bottom-margin-1">
              vous êtes
            </h3>
          </div>
          <div class="buttons is-centered">
            <button v-for="(accountType, index) in accountTypes" :key="'account-type-'+index" type="button" class="button" :class="[!notError.accountType ? 'is-danger is-outlined' : '', account_type_id == accountType.id ? 'is-primary' : '']" @click="[notError.accountType=true, account_type_id=accountType.id]" v-model="account_type_id">{{accountType.name}}</button>
          </div>
          <div class="buttons is-centered has-bottom-margin-1">
            <button v-for="(userGender, index) in userGenders" :key="'user-gender-'+index" type="button" class="button" :class="[!notError.userGender ? 'is-danger is-outlined' : '', user_gender_id == userGender.id ? 'is-primary' : '']" @click="[notError.userGender=true, user_gender_id=userGender.id]" v-model="user_gender_id">{{userGender.name}}</button>           
          </div>
          <div class="has-text-centered has-bottom-margin-1">
            <FacebookLogin @signup-fb-init="signupInit" />
            <GoogleLogin @signup-google-init="signupInit" />
          </div>   
          <p class="has-text-centered has-bottom-margin-1">ou</p>
          <div class="field jsmb-email-account">
            <div class="control has-icons-right">
              <input class="input" v-model="emailNoChecked" type="email" placeholder="Votre email" value="Votre email" required>
              <span class="icon has-text-primary is-small is-right">
                <i class="fas fa-envelope"></i>
              </span>
            </div>
            <p class="help is-danger" :class="{'is-hidden': notError.email}">{{errorMsg.email}}</p>
          </div>
          <b-field>
            <b-input name="password" type="password" v-model="password" placeholder="Mot de passe" minlength=8 maxlength=20 password-reveal required></b-input>
          </b-field>

          <div class="field">
              <div class="control">
                <input class="input" v-model="first_name" type="text" placeholder="Votre prénom" value="Votre prénom" required>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input" v-model="last_name" type="text" placeholder="Votre nom" value="Votre nom" required>
            </div>
          </div>

          <div class="field">
            <label class="checkbox is-size-6">
              <input type="checkbox" v-model="cgu" required> J’ai lu et j’accepte la <router-link :to="{name: 'DatasPolitics'}" tag="a" push>politique de gestion des donnees</router-link> de JeStockeMonBateau
            </label>
          </div>
          <div class="field firststeplast is-size-6 has-bottom-margin-1">
            <label class="checkbox">
              <input  type="checkbox" v-model="newsletter"> Je souhaite recevoir par e-mail les actualités sur JeStockeMonBateau et ses partenaires.
            </label>
          </div>
          <div class="has-text-centered">
            <button type='submit' class="button is-danger" :class="buttonDoneLoad?'is-loading':''" :disabled="buttonDoneLoad?true:false">Créer mon compte</button>
          </div>
        </form>       
    </div>
</template>
<script>
  import FacebookLogin from '@/components/template/ui/FacebookLogin.vue'
  import GoogleLogin from '@/components/template/ui/GoogleLogin.vue'
  import { SIGNUP_CHECK_MAIL, SIGNUP_REQUEST } from '@/store/modules/entrance/actions/entrance.actions'
  import { GET_ACCOUNT_TYPE, GET_USER_GENDER } from '@/store/modules/database/actions/database.actions'
  import { error } from 'util';

  export default {
    name: 'Signup',
    components: {
      FacebookLogin,
      GoogleLogin
    },
    data(){
      return {
        accountTypes: [],
        userGenders: [],

        notError: {
          email: true,
          accountType: true,
          userGender: true
        },
        errorMsg: {
          email: ''
        },

        cgu: JSON.parse(localStorage.getItem('cgu')) || false,
        newsletter: JSON.parse(localStorage.getItem('newsletter')) || false,
        emailNoChecked: '',
        email: localStorage.getItem('emailAddress') || '',
        password: localStorage.getItem('password') || '',
        first_name: localStorage.getItem('first_name') || '',
        last_name: localStorage.getItem('last_name') || '',
        facebook_id: localStorage.getItem('facebook_id') || '',
        google_id: localStorage.getItem('google_id') || '',
        user_type_id: 1,
        account_type_id: localStorage.getItem('account_type_id') || '',
        user_gender_id: localStorage.getItem('user_gender_id') || '',
        bio: '',
        skipBoat: false,
        boatData: {
          boat_type_id: 0,
          length: 1,
          height: 1,
          width: 1,
        },
        imgSrc: require('./../../../assets/profil-modal.png'),
        phone: '',
        buttonDoneLoad: false,
      }
    },
    methods: {
      signupInit () {
        this.email = localStorage.getItem('emailAddress')
        this.cgu = JSON.parse(localStorage.getItem('cgu'))
        this.newsletter = JSON.parse(localStorage.getItem('newsletter'))
        this.notError.email = true
        this.errorMsg.email = ''      

        this.first_name = localStorage.getItem('first_name')
        this.last_name = localStorage.getItem('last_name')
        if (localStorage.getItem('facebook_id') !== null) {
          this.facebook_id = localStorage.getItem('facebook_id')
        }else if (localStorage.getItem('google_id') !== null) {
          this.google_id = localStorage.getItem('google_id')        
        }
      },
      signup () {
        this.buttonDoneLoad = true
        if (this.account_type_id !== '' && this.user_gender_id !== '') {
          this.notError.phone = true
          this.errorMsg.phone = ''
  
          const { newsletter, email, password, first_name, last_name, facebook_id, google_id, user_type_id, account_type_id, user_gender_id, bio, boatData, imgSrc, phone } = this
          this.$store.dispatch(
            SIGNUP_REQUEST,
            { newsletter, email, password, first_name, last_name, facebook_id, google_id, user_type_id, account_type_id, user_gender_id, bio, imgSrc, phone }
          ).then(
            resp => {
              const {success, msg, data} = resp.data.response
              this.buttonNextLoad = false
              if (success && this.skipBoat) {
                // let userId = data.id;
                let userId = data.user_id;
                this.$store.dispatch(
                  ADD_BOAT_REQUEST,
                  { userId, boatData }
                )
              }
              localStorage.removeItem('emailAddress')
              localStorage.removeItem('cgu')
              localStorage.removeItem('newsletter')
              localStorage.removeItem('password')
              localStorage.removeItem('first_name')
              localStorage.removeItem('last_name')
              localStorage.removeItem('facebook_id')
              localStorage.removeItem('google_id')
              localStorage.removeItem('google_img')
              localStorage.removeItem('user_type_id')
              localStorage.removeItem('account_type_id')
              localStorage.removeItem('user_gender_id')
              localStorage.removeItem('bio')
              localStorage.removeItem('boat_type_id')
              localStorage.removeItem('boat_length')
              localStorage.removeItem('boat_height')
              localStorage.removeItem('boat_width')
              // Suppression Vérification Mail
              switch (data.user_type) {
                case 'stockeur':
                  this.$router.push({ name: 'Wizard' })
                  break;
  
                case 'skipper':
                  this.$router.push({ name: 'Account' })
                  localStorage.removeItem('modalCurrentStep')
                  break;
              }
              this.$emit('change-current-step', isNext)
              this.switchShowButton(this.currentStep+1)
            }
          ).catch(
            error => {
              this.buttonDoneLoad = false
            }
          )
        }else{
          this.buttonDoneLoad = false
          if (this.account_type_id === '') {
            this.notError.accountType = false
          }
          if (this.user_gender_id === '') {
            this.notError.userGender = false
          }
        }
      }
    },
    created () {
      // Get AccountTypes
      this.$store.dispatch(
        GET_ACCOUNT_TYPE
      ).then(
        resp => {
          this.accountTypes = resp
        }
      )
      // Get UserGenders
      this.$store.dispatch(
        GET_USER_GENDER
      ).then(
        resp => {
          this.userGenders = resp
        }
      )
    },
    watch: {
      emailNoChecked(email) {
        this.$store.dispatch(
          SIGNUP_CHECK_MAIL, { email }
        ).then(
          resp => {
            this.notError.email = true
            this.errorMsg.email = ''
            this.email = email
          }
        ).catch(
          error => {
            const { msg } = error.data.response
            this.notError.email = false
            this.errorMsg.email = msg['email'][0]
            this.email = ''
          }
        )
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  .has-bottom-margin-1 {
    margin-bottom: 1rem;
  } 
  .button {
    margin-bottom: 1rem !important;
  }

  .has-bottom-margin-3 {
    margin-bottom: 3rem;
  }
  .has-padding-8 {
    padding: 8%;
  }
  .space-around-buttons {
    justify-content: space-around;
  }
  .has-white-bg{
    background-color: #fff;
  }
</style>

<style  lang="css">

</style>
